const theme = {
  colors: {
    primary: '#1c252b',
    bg: '#f2f2f2',
    black: '#000000',
    white: '#ffffff',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b',
    light: '#9d9d9d',
    mediumGrey: '#888888'
  },
  fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
  maxWidth: '1072px',
  maxWidthText: '720px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '775px',
    m: '1054px'
  },
}

export default theme
