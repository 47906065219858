import React from 'react'
import { Global, css, ThemeProvider } from '@emotion/react'
import { MainContainer } from '../containers'
import { Seo, Header, Footer, FooterMenu } from './'
import { theme, reset } from '../styles'
import '../styles/icons.css';
import 'typeface-lato'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.light};
  }
  body {
    color: ${theme.colors.light};
    background: #1a1a1a;
    font-size: 1.6rem;
    .grayscale {
      background: #1a1a1a;
      opacity: .9;
      > div {
        filter: grayscale(1);
        opacity: .5;
        transition: all 0.5s ease; 
        &.mobile {
          display: none
        }
        @media (max-width: ${theme.breakpoints.s}) {
          &.mobile {
            display: block
          }
          &.collapse {
            display: none
          }
        }
      }
      &:hover {
        > div {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }
    .customBackground {
      content: "";
      display: block;
      position: fixed;
      z-index: -1;
      filter: blur(8px);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 110%;
      width: 100%;
      height: 100vh;
      filter: grayscale(.5);
      opacity: .05;
      @media (max-width: ${theme.breakpoints.s}) {
        background-size: cover;
      }
    }
    .mobile {
      display: none
    }
    @media (max-width: ${theme.breakpoints.s}) {
      .mobile {
        display: block
      }
      .collapse {
        display: none
      }
    }
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  img {
    margin: 0;
  }
  a {
    color: ${theme.colors.light};
    transition: all .2s ease-in-out;
    text-decoration: none;
    &:hover,
    &:focus,
    &:visited,
    &:active {
      text-decoration: none;
      color: ${theme.colors.light};
    }
    &.contact {
      font-size: 2.5rem;
      &:hover {
        color: ${theme.colors.white};
      }
    }
  }
`

class Layout extends React.Component {

  render() {
    const { 
      context,
      children, 
      background, 
      cover, 
      customBackground
    } = this.props;
    const style = background && {
      background: `url(${background.publicURL})`,
      backgroundSize: cover ? 'cover' : 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    }
    const customStyle = customBackground && {
      backgroundImage: `url(${customBackground.publicURL})`
    }
    return (
      <ThemeProvider theme={theme}>
        {
          customBackground && (
            <div 
              style={customStyle}
              className="customBackground"
            ></div>
          )
        }
        <Seo {...context} />
        <Header />
        <MainContainer style={style}>
          <Global styles={globalStyle} />
          {children}
        </MainContainer>
        <FooterMenu />
        <Footer />
      </ThemeProvider>
    )
  }
}

export default Layout;
