import React from 'react'

const AlbumInteractions = ({
  bandcamp,
  spotify,
  applemusic,
  otherLinks
}) => {
  return (
    <React.Fragment>
      <h3>Écouter{`${(!spotify && !applemusic) ? ' et acheter' : ''}`} sur</h3>
      <div>
        {
          bandcamp && (
            <a href={bandcamp} target="_blank" rel="noreferrer">
              <i className="icon-bandcamp"></i> Bandcamp
            </a>
          )
        }
        {
          spotify && (
            <a href={spotify} target="_blank"  rel="noreferrer">
              <i className="icon-spotify"></i> Spotify
            </a>
          )
        }
        {
          applemusic && (
            <a href={applemusic} target="_blank" rel="noreferrer">
              <i className="icon-apple"></i> Apple Music
            </a>
          )
        }
      </div>

      {
        (!spotify && !applemusic) ? (
          <React.Fragment>
            {
              otherLinks.length > 0 && (
                <React.Fragment>
                  {
                    otherLinks[0].link.url && (
                      <React.Fragment>
                        <h3>Acheter sur</h3>
                        <div>
                          {
                            otherLinks.map((i, n) => {
                              return (
                                <a 
                                  href={i.link.url}
                                  target="_blank" 
                                  rel="noreferrer"
                                  key={`more-links-${n}`}
                                >
                                  {i.label}
                                </a>
                              )
                            })
                          }
                        </div>
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            {
              (bandcamp || otherLinks.length > 0) && (
                <React.Fragment>
                  {
                    otherLinks[0].link.url && (
                      <React.Fragment>
                        <h3>Acheter sur</h3>
                        <div>
                          {
                            bandcamp && (
                              <a href={bandcamp} target="_blank" rel="noreferrer">
                                <i className="icon-bandcamp"></i> Bandcamp
                              </a>
                            )
                          }
                          {
                            otherLinks.map((i, n) => {
                              return (
                                <a 
                                  href={i.link.url}
                                  target="_blank" 
                                  rel="noreferrer"
                                  key={`more-links-${n}`}
                                >
                                  {i.label}
                                </a>
                              )
                            })
                          }
                        </div>
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )
            }
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

export default AlbumInteractions;