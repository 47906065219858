import React from 'react'
import AlbumsCollection from "./AlbumsCollection"
import Video from "./Video"
import ScheduledEvent from "./ScheduledEvent"
import { TextPage } from "../containers"

const PrismicSlice = ({ body }) => {
  return (
    <React.Fragment>
      {
        body.map((item, index) => {
          return (
            <React.Fragment key={`prismic-slice-${index}`}>
              {
                <React.Fragment>
                  {
                    item.primary && (
                      <React.Fragment>
                        {
                          Object.keys(item.primary).map((i, n) => {
                            if (i === 'texte') {
                              return (
                                <div 
                                  key={`item-primary-${n}`}
                                >
                                  <TextPage dangerouslySetInnerHTML={{ __html: item.primary[i].html }} />
                                </div>
                              )
                            } else if (i === 'albums_collection') {
                              return (
                                <AlbumsCollection
                                  key={`item-primary-${n}`} 
                                  {...item.primary[i].document.data}
                                />
                              )
                            } else if (i === 'video') {
                              return (
                                <Video
                                  key={`item-primary-${n}`}
                                  {...item.primary[i].document.data}
                                />
                              )
                            } else {
                              return (
                                <div 
                                  style={{ display: 'none' }}
                                  key={`item-blank-${n}`}
                                ></div>
                              )
                            }
                          })
                        }
                      </React.Fragment>
                    )
                  }
                  {
                    item.items && (
                      <React.Fragment>
                        {
                          Object.keys(item.items[0])[0] === 'dates' && (
                            <React.Fragment>
                              {
                                item.items.map((i, n) => {
                                  const {
                                    dates: {
                                      document: {
                                        data
                                      }
                                    }
                                  } = i;
                                  return (
                                    <ScheduledEvent 
                                      key={`date-${n}`}
                                      {...data}
                                    />
                                  )
                                })
                              }
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              }
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  )
}

export default PrismicSlice;