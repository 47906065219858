import styled from '@emotion/styled'
import { theme } from '../styles'

export const AlbumContainer = styled.article`
  > div {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.s}) {
      display: block;
    }
    &> div {
      width: 50%;
      @media (max-width: ${theme.breakpoints.s}) {
        width: 100%;
      }
      &:first-of-type {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 50rem;
          max-height: 50rem;
          overflow: hidden;
          border-radius: .5rem;

          > img {
            width: 110%;
            max-width: 110%;
          }

          @media (max-width: ${theme.breakpoints.m}) {
            max-width: 36rem;
            max-height: 36rem;
          }
          @media (max-width: ${theme.breakpoints.s}) {
            width: 100%;
            max-width: 100%;
            height: calc(100vw - 4rem);
            max-height: calc(100vw - 4rem);
            > img {
              width: 115%;
              max-width: 115%;
            }
          }

          &.links {
            display: block;
            margin-top: 2rem;
            > div {
              margin-top: 1rem;
              margin-bottom: 1.5rem;
              display: flex;
              flex-wrap: wrap;

              a {
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                padding: .2rem 1rem .3rem;
                margin: 0 1rem;
                line-height: 2.2rem;

                &:first-of-type {
                  margin-left: 0;
                }
                &:last-of-type {
                  margin-right: 0;
                }
                border: .1rem solid ${theme.colors.light};
                border-radius: .8rem;

                &:hover {
                  background: ${theme.colors.light};
                  color: ${theme.colors.black}
                }

                i {
                  display: block;
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
      &:last-of-type {
        @media (max-width: ${theme.breakpoints.s}) {
          margin-top: 2rem;
        }
        h1 {
          font-size: 3.5rem;
          margin: 0 0 2rem;
          line-height: 2.5rem;
        }
        h2, h3 {
          margin: 0;
        }
        > div {
          p {
            a {
              color: ${theme.colors.light};
              text-decoration: underline;
              &:hover {
                color: ${theme.colors.white};
              }
            }
          }
          h4 {
            font-size: 2rem;
            margin: 2rem 0 0 0;
          }
          &.links {
            margin-top: 3rem;
            > div {
              margin-top: 1rem;
              margin-bottom: 1.5rem;
              display: flex;
              flex-wrap: wrap;

              a {
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                padding: .2rem 1rem .3rem;
                margin: 0 1rem;
                line-height: 2.2rem;

                &:first-of-type {
                  margin-left: 0;
                }
                &:last-of-type {
                  margin-right: 0;
                }
                border: .1rem solid ${theme.colors.light};
                border-radius: .8rem;

                &:hover {
                  background: ${theme.colors.light};
                  color: ${theme.colors.black}
                }

                i {
                  display: block;
                  margin-right: .5rem;
                }
              }
            }
          }
        }
      }
    }
  }
`