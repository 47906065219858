import React from 'react'
import { FooterContainer, Text } from '../containers'

const Footer = () => (
  <FooterContainer className="grayscale">
    <div className="collapse">
      <Text>&copy; {new Date().getFullYear()} www.arnaudquevedo.com</Text>
    </div>
    <div>
      <a 
        href="https://arnaudquevedo.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
        className="icons"
      >
        <i className="icon-bandcamp"></i>
      </a>
      <a 
        href="https://music.apple.com/fr/artist/arnaud-quevedo/1580714228"
        target="_blank"
        rel="noreferrer"
        className="icons"
      >
        <i className="icon-apple"></i>
      </a>
      <a
        href="https://open.spotify.com/artist/7gAsoYRJMiFqq1RTjY1E3l"
        target="_blank"
        rel="noreferrer"
        className="icons"
        style={{ marginRight: '3rem' }}
      >
        <i className="icon-spotify"></i>
      </a>
      <a 
        href="https://www.youtube.com/user/DarthNohks"
        target="_blank"
        rel="noreferrer"
        className="icons"
      >
        <i className="icon-youtube"></i>
      </a>
      <a 
        href="https://www.facebook.com/ArnaudQuevedoMusic"
        target="_blank"
        rel="noreferrer"
        className="icons"
      >
        <i className="icon-facebook"></i>
      </a>
    </div>
    <div className="mobile">
      <Text>&copy; {new Date().getFullYear()} www.arnaudquevedo.com</Text>
    </div>
  </FooterContainer>
)

export default Footer;
