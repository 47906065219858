import styled from '@emotion/styled'
import { theme } from '../styles'

export const DateContainer = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
  }
  > div {
    width: 50%;
    @media (max-width: ${theme.breakpoints.s}) {
      width: 100%;
      text-align: center;
    }
    padding: 0 1rem;
    &:first-of-type {
      font-size: 2.2rem;
      font-weight: bold;
      text-align: right;
      @media (max-width: ${theme.breakpoints.s}) {
        text-align: center;
      }
    }
    &:last-of-type {
      font-size: 1.8rem;
    }
  }
`