import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { FooterMenuContainer } from '../containers'
import { Menu } from './'

const FooterMenu = () => {
  const links = [
    {
      label: 'Écouter',
      link: '/ecouter/'
    },
    {
      label: 'Dates',
      link: '/dates/'
    },
    {
      label: 'Biographie',
      link: '/biographie/'
    },
    {
      label: 'Contact',
      link: '/contact/'
    },
  ];

  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      file(base: {eq: "logo-w.png"}) {
        publicURL
      }
    }
  `);

  return (
    <FooterMenuContainer className="mobile">
      <div>
        <Link to={`/`}>
          <img 
            src={data.file.publicURL}
            alt="Arnaud Quevedo & Friends"
          />
        </Link>
      </div>
      <div>
        <Menu links={links} />
      </div>
    </FooterMenuContainer>
  )
}

export default FooterMenu;