import React from 'react'
import styled from '@emotion/styled'
import YouTube from 'react-youtube';
import { 
  getVideoProvider, 
  getVideoId
} from "../utils/mediaHelpers"

const VideoContainer = styled.div`
  margin: 5rem 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
  }
`

const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

const Video = ({ video }) => {
  
  let videoId = null;
  let provider = null;

  if (video.embed_url) {
    provider = getVideoProvider(video.embed_url);
    if (provider) {
      videoId = getVideoId(provider, video.embed_url);
    }
  }
  
  return (
    <React.Fragment>
      {
        videoId && (
          <VideoContainer>
            <YouTube 
              videoId={videoId}
              opts={videoOpts} 
            />
          </VideoContainer>
        )
      }
    </React.Fragment>
  )
}

export default Video;