import React from 'react'
import { Link } from "gatsby"
import { 
  AlbumsCollectionContainer,
  AlbumsCollectionItemContainer,
} from "../containers"
import AlbumInteractions from "./AlbumInteractions"

const AlbumsCollection = (props) => {
  return (
    <AlbumsCollectionContainer>
      {
        props.items.length > 0 && (
          <React.Fragment>
            {
              props.items.map((value, index) => {
                
                const links = {
                  bandcamp: value.item.document.data.bandcamp.url,
                  spotify: value.item.document.data.spotify.url,
                  applemusic: value.item.document.data.applemusic.url,
                  otherLinks: value.item.document.data.more_links,
                }

                return (
                  <AlbumsCollectionItemContainer 
                    key={`item-${index}`}
                    className="album"
                  >
                    <div>
                      <Link to={`/ecouter/${value.item.document.uid}/`}>
                        <img 
                          src={value.item.document.data.cover.localFile.publicURL}
                          alt={value.item.document.data.cover.alt}
                        />
                      </Link>
                    </div>
                    <div>
                      <h2>
                        <Link to={`/ecouter/${value.item.document.uid}/`}>
                          {value.item.document.data.title.text}
                        </Link>
                      </h2>
                      <AlbumInteractions {...links} />
                    </div>
                  </AlbumsCollectionItemContainer>
                )
              })
            }
          </React.Fragment>
        )
      }
    </AlbumsCollectionContainer>
  )
}

export default AlbumsCollection;