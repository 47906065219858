import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Helmet from 'react-helmet'

const Seo = (props) => {
  
  const query = useStaticQuery(graphql`
    query Seo {
      site {
        buildTime(formatString: "YYYY-MM-DD")
      }
    }
  `);

  const { site } = query;
  const { title, description, ogimage, slug } = props;
  const url = 'https://www.arnaudquevedo.com';
  const author = 'Arnaud Quevedo';
  const data = {
    site,
    title, 
    description, 
    ogimage,
    slug
  }
  
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${url}${slug}`,
    headline: data.title,
    inLanguage: `fr`,
    mainEntityOfPage: `${url}${slug}`,
    description: data.description,
    name: data.title,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2022-02-04T10:30:00+01:00',
    dateModified: site.buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${url}${ogimage}`,
    },
  }
  
  const itemListElement = [
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-listen",
      "name": "Écouter",
      "url": `${url}/ecouter/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-dates",
      "name": "Dates",
      "url": `${url}/dates/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-biography",
      "name": "Biographie",
      "url": `${url}/biographie/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-contact",
      "name": "Contact",
      "url": `${url}/contact/`
    }
  ]

  const SiteNavigation = {
    '@context': 'http://schema.org',
    "@graph": itemListElement
  }

  return (
    <Helmet title={title}>
      <html lang={`fr`} />

      <meta name="description" content={description} />
      <meta name="image" content={`${url}${ogimage}`} />
      <meta name="title" content={title} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={'website'} />
      <meta property="og:url" content={`${url}${slug}`} />
      <meta property="og:locale" content={`fr`} />
      <meta property="og:image" content={`${url}${ogimage}`} />

      <link rel="canonical" href={`${url}${slug}`}></link>
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(SiteNavigation)}</script>
    </Helmet>
  )
}

export default Seo;
