import styled from '@emotion/styled'
import { theme } from '../styles'

export const MainContainer = styled.main`
  min-height: 100vh;
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  @media (max-width: ${theme.breakpoints.s}) {
    min-height: calc(100vh - 31.2rem);
  }
  > div {
    min-height: 100vh;
    padding: 12rem 2rem 10rem;
    max-width: 107.2rem;
    @media (max-width: ${theme.breakpoints.s}) {
      min-height: calc(100vh - 31.2rem);
      padding-top: 2rem;
    }
    &.full {
      max-width: 100%;
    }
    margin: 0 auto;
    > a > img {
      max-height: calc(100vh - 20rem);
    }
  }
`