import styled from '@emotion/styled'
import { theme } from '../styles'

export const H1 = styled.h1`
  display: inline-block;
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-family: ${theme.fontFamily};
  color: ${theme.colors.light};
  margin: 0;
  padding: 0;
  > span, a span {
    display: block;
  }
`

export const Text = styled.span`
  display: inline-block;
  font-size: 1.6rem;
  font-family: ${theme.fontFamily};
  color: ${theme.colors.light};
  margin: 0;
  padding: 0;
  > span {
    display: block;
  }
`

export const TextPage = styled.span`
  display: block;
  p {
    font-family: ${theme.fontFamily};
    font-size: 1.8rem;
    color: ${theme.colors.light};
    text-align: justify;
    a {
      color: ${theme.colors.light};
      text-decoration: underline;
      &:hover {
        color: ${theme.colors.white};
      }
    }
  }
`