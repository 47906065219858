import styled from '@emotion/styled'
import { theme } from '../styles'

export const FooterContainer = styled.footer`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 10rem;
  margin: 0;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    position: relative;
  }

  > div {
    display: flex;
    align-items: center;
    @media (max-width: ${theme.breakpoints.s}) {
      justify-content: center;
      text-align: center;
      &:last-of-type {
        margin-top: 2rem;
      }
    }
    a.icons {
      display: inline-block;
      margin: 0 .5rem;
      font-size: 2.5rem;
      color: ${theme.colors.light};

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        color: ${theme.colors.white};
      }
    }
  }
`