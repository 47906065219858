import React from 'react'
import { DateContainer } from '../containers/DateContainer'

const ScheduledEvent = ({ date, description }) => {
  return (
    <DateContainer>
      <div>{date}</div>
      <div dangerouslySetInnerHTML={{ __html: description.text }}></div>
    </DateContainer>
  )
}

export default ScheduledEvent;