import styled from '@emotion/styled'
import { theme } from '../styles'

export const AlbumsCollectionItemContainer = styled.article`
  display: block;
  width: 45rem;
  min-height: 100px;
  margin-bottom: 4%;
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.m}) {
    width: 35rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
    height: auto;
    margin-bottom: 5rem;
  }
  
  &.album {
    > div:first-of-type {
      display: block;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45rem;
        height: 45rem;
        overflow: hidden;
        border-radius: .5rem;
        > img {
          width: 110%;
          max-width: 110%;
        }
        @media (max-width: ${theme.breakpoints.m}) {
          max-width: 35rem;
          max-height: 35rem;
        }
        @media (max-width: ${theme.breakpoints.s}) {
          width: 100%;
          max-width: 100%;
          height: calc(100vw - 4rem);
          max-height: calc(100vw - 4rem);
          > img {
            width: 115%;
            max-width: 115%;
          }
        }
      }
    }
    > div:last-of-type {
      h2, h3 {
        margin: 0;
      }
      h2 {
        font-size: 2.5rem;
        margin: 1rem 0;
        @media (max-width: ${theme.breakpoints.m}) {
          font-size: 2rem;
        }
        @media (max-width: ${theme.breakpoints.s}) {
          font-size: 2.5rem;
        }
      }

      > div {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-wrap: wrap;

        a {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          padding: .2rem 1rem .3rem;
          margin: 0 1rem;
          line-height: 2.2rem;

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
          border: .1rem solid ${theme.colors.light};
          border-radius: .8rem;

          &:hover {
            background: ${theme.colors.light};
            color: ${theme.colors.black}
          }

          i {
            display: block;
            margin-right: .5rem;
          }

          @media (max-width: ${theme.breakpoints.s}) {
            margin: 0 1rem 0 0;
          }
        }
      }
    }
  }
`