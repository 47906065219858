import styled from '@emotion/styled'
import { theme } from '../styles'

export const MenuContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  @media (max-width: ${theme.breakpoints.s}) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  > li {
    list-style-type: none;
    font-family: ${theme.fontFamily};
    font-size: 1.8rem;
    text-transform: uppercase;
    margin: 0 1rem;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 2.2rem;
      margin: .2rem 1rem;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    a {
      color: ${theme.colors.light};
      &:hover {
        color: ${theme.colors.white};
      }
      &.active {
        color: ${theme.colors.white};
      }
    }
  }
`