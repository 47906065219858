import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { HeaderContainer, H1 } from '../containers'
import { Menu } from './'

const Header = () => {
  const links = [
    {
      label: 'Écouter',
      link: '/ecouter/',
      id: 'nav-ecouter'
    },
    {
      label: 'Dates',
      link: '/dates/',
      id: 'nav-dates'
    },
    {
      label: 'Biographie',
      link: '/biographie/',
      id: 'nav-biographie'
    },
    {
      label: 'Contact',
      link: '/contact/',
      id: 'nav-contact'
    },
  ];

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      file(base: {eq: "logo-w.png"}) {
        publicURL
      }
    }
  `);

  return (
    <HeaderContainer className="grayscale collapse">
      <div>
        <Menu links={links} />
      </div>
      <div>
        <div>
          <H1 style={{ textAlign: 'right' }}>
            <Link to={`/`}>
              <span style={{ color: '#ffffff' }}>Arnaud Quevedo</span>
              <span style={{ color: '#9d9d9d' }}>& Friends</span>
            </Link>
          </H1>
        </div>
        <Link to={`/`}>
          <img 
            src={data.file.publicURL}
            alt="Arnaud Quevedo & Friends"
            style={{
              width: '6rem',
              marginLeft: '2rem'
            }}
          />
        </Link>
      </div>
    </HeaderContainer>
  )
}

export default Header;