import React from 'react'
import { Link } from "gatsby"
import { MenuContainer } from '../containers'

const Menu = ({ links }) => {
  return (
    <MenuContainer>
      {
        links.map((item, index) => {
          return (
            <li key={`menu-link-${index}`}>
              {
                item.dist ? (
                  <a 
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      item.icons ? (
                        <i className={`icons ${item.icon}`}></i>
                      ) : (
                        item.label
                      )
                    }
                  </a>
                ) : (
                  <Link 
                    to={item.link}
                    target="_blank"
                    activeClassName="active"
                    partiallyActive={true}
                    id={item.id}
                  >
                    {item.label}
                  </Link>
                )
              }
            </li>
          )
        })
      }
    </MenuContainer>
  )
}

export default Menu;